.toolBarWrapper {
    width: 420px;
    margin: 0 auto;
}

.dropdown {
    position: relative;
    left: 350px;
    bottom: 20px;
    width: 100px;
}

.dropdown .toolbarButton {
    background-color: #7F7ACF;
    color: white;
}

.dropdown .toolbarButton:hover {
    color: #7F7ACF
}