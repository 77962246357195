
section {
  color: #fff;
  text-align: center;
}

article {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 20px;
}

h1 {
  font-size: 1.75rem;
  margin: 0 0 0.75rem 0;
}

.mainContainer {
  display: table;
  width: 100%;
}

.left-half-welcome {
  background-color: #3381DD;
  position: absolute;
  left: 0px;
  width: 50%;
  height: 100%;
}

.right-half-welcome {
    background-color: white;
    position: absolute;
    right: 0px;
    width: 50%;
    height: 100%;
}

.welcome-description {
    width: 30%;
    margin: 0 auto;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.treasuryLogo {
    display: block;
    border: none;
    margin: 20px auto;
    width: 55%;
    height: 100px !important;
}

.deLogo {
    position: fixed;
    border: none;
    left: 40px;
    top: 30px;
    width: 60px;
    height: 60px;
}

.triangle-left {
  position: relative;
  top: 45%;
  left: 96%;
	width: 10px;
	height: 10px;
	border-top: 50px solid transparent;
	border-right: 50px solid white;
	border-bottom: 50px solid transparent;
}