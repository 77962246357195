.controlButtonLogout {
    margin: 10px 10px;
    padding: 5px 10px;
    border: 1px solid gray;
  }
  
  .dashboardColor {
    color: rgb(165, 18, 165);
  }
  
  .logoutColor {
    background-color: #3381DD;
    color: white;
    border-radius: 25px;
  }
  