
.refImage {
    width: 50px;
    height: 50px;
}

.deleteButton {
    display: flex;
    float: right;
}

.deleteCard {
    margin: 10px 10px;
    background-color: rgba(139,178,255, 0.1);
}

.deleteContainer {
    margin-top: 3%;
    border: 1px solid gray;
}