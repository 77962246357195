
.searchWrapper {
    position: relative;
    top: 17px;
}

.searchWrapper i {
    position: absolute;
    top: 0px;
    left: 325px;
}

.searchBar {
    max-width: 500px;
    width: 350px;
    height: 31px;

    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}