.card {
    width: 50rem;
    /* text-align: center; */
}

.historyTitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 3%;
    color: black;
}

.historyContainer {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.contentTitleHistory {
    float: left;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 18px;
}
.contentDate {
    float: right;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}