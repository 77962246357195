.resetDownloadContainer {
    margin-top: 3%;
    margin-bottom: 3%;
    border: 1px solid gray;
    padding: 2% 2%;
    background-color: rgba(139,178,255, 0.1);
}

.dataPoint {
    color: black;
}

#dataSection {
    margin: 20px 20px;
}
.historyData {
    margin: 20px 20px;
}
.moreButton {
    display: flex;
    float: right;
}

.userSearch {
    max-width: 40%;
}

.searchButton {
    display: inline-block;
}

.userError {
    color: red;
}