
.columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    margin-top: 2%;
    margin-right: 2%;
  }

  .loader {
    margin-top: 15%;
    border: 10px solid #f3f3f3; 
    border-top: 10px solid #34d0db; 
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }