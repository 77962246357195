
body {
  background-color: white;
}

.navbar {
  background-color: rgba(139,178,255, 0) !important;
}

.treasuryLogo {
  height: 40px;
}

.contentCard {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Lato';
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  border-radius: 25px !important;
  /* box-shadow: 1px 1px rgb(180, 180, 180); */
}
.overlay{
  opacity: 0.4;
}

.sizeIndicator {
  font-size: 12px;
}

.cardDescription {
  margin-left: 2%;
  margin-right: 2%;
  font-size: 10pt;
}

#dashboardButton {
  display: inline-block;
  margin-top: 7%;
  border: 1px solid gray;
  padding: 5px 10px;
  color: green;
}

.controlButton {
  border-radius: 25px;
  background: white;
  margin: 10px 10px;
  padding: 5px 10px;
  border: 1px solid gray;
}

.controlButtonTransfer {
  border-radius: 25px;
  background: white;
  width: 82px;
  margin: 10px 10px;
  padding: 5px 10px;
  border: 1px solid gray;
}

.viewColor {
  color: #3381DD;
}

.viewColor:hover {
  background: #3381DD;
  color: white;
}

.transferColor {
  color: #3381DD;
}

.transferColor:hover {
  background: #3381DD;
  color: white;
}

.cardButtons {
  margin-top: 50px;
}

img {
  height: 300px;
  width: 280px;
}

.imageContainer {
  position: relative;
  width: 280px;
  height: 300px;
}

.contentInfo {
  position: relative;
  top: 0px;
  color: black;
  margin: 0px 5px;
}

.contentTitle {
  display: flex;
  width: 280px;
  justify-content: center;
  font-size: 16pt;
}

.transfered {
  margin-left: 5px;
  display: inline-block;
  margin-top: 8px;
  color:#20c997;
}

.transferedView {
  margin: 0 auto;
  display: inline-block;
  margin-top: 8px;
  color:#20c997;

}

.notTransfered {
  display: none;
}

div li button:hover {
  color: black;
}

.emailDisplay {
  display: inline-block;
  color: gray !important;
}

.zeroVisibility {
  display: none;
}

/* Spinner code for transfer content */

.spinner {
  width: 15px;
  height: 15px;
  background-color: #17a2b8;
  display: inline-block;
  margin-top: 12px;

  margin-left: 15px;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.spinnerView {
  width: 15px;
  height: 15px;
  background-color: #17a2b8;
  display: inline-block;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}