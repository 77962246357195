
#editFormContainer {
    margin-top: 3%;
    margin-bottom: 3%;
    border: 1px solid gray;
    background-color: rgba(139,178,255, 0.1);
}

#editForm {
    padding: 20px 20px;
}
