.navButtons {
    margin: auto;
}

.selected {
    color: green;
}

.addForm {
    margin-top: 50px;
    background-color: white;
}

small .valid {
    color: green;
}

.invalid {
    color: red !important;
}