h2 {
    color: #66fcf1;
    display: table;
    margin: 50px auto;
  }

.loadingScreen {
  display: flex;
  justify-content: center;
  align-content: center;
}

.loadingImage {
  margin-top: 10%;
  width: 500px;
  height: 500px;
}

.uiElement {
  height: 85%;
}

.buttonBackView {
  margin-top: 5%;
  border-radius: 25px;
  border: 1px solid #767676;
  background-color: #3381DD;
  color: white;
  padding: 10px;
}

.buttonTransferView {
  width: 82px;
  border-radius: 25px;
  border: 1px solid #767676;
  margin-top: 5%;
  background-color: white;
  color: #3381DD;
  padding: 10px;
}

.contentTitleView {
  text-align: center;
  font-family: lato;
  margin-bottom: 2%;
}

/* Styles for viewing card */

.split {
  height: 100%;
  width: 50%;
  position: fixed;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.imgContainerView {
  display: flex;
  width: 320px;
  margin: 0 auto;
}

.cardInfoView {
  margin-left: 10px;
  margin-right: 10px;
}

@media only screen and (max-width : 768px) {
  .split {
    position: static;
  }

  .left {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .right {
    display: block;
    width: 100%;
    height: 100%;
  }

  .imgContainerView {
    height: 250px;
  }
}


/* UI element loader css code */

.loaderContainer {
  margin-left: 46%;
  margin-top: 30%;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
