
#addFormContainer {
    margin-top: 3%;
    margin-bottom: 3%;
    border: 1px solid gray;
    background-color: rgba(139,178,255, 0.1);
}

#addForm {
    padding: 20px 20px;
}

.addContentVisible {
    display: block;
}

.addContentInvisible {
    display: none;
}

.loadingFolder {
    color: red;
}

.hideIndicator {
    display: none;
}